/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    "./src/pages/**/*.{js,jsx,ts,tsx}",
    "./src/components/**/*.{js,jsx,ts,tsx}",
    "./src/sections/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    screens: {
      sm: "480px",
      md: "768px",
      lg: "976px",
      xl: "1440px",
    },
    fontFamily: {
      "sans": ["Inter", 'Helvetica', 'Arial', 'sans-serif'],
    },
    extend: {
      colors: {
        "primary": "#CC5BA7",
        "primary-dark": "#99457D",
        "primary-light": "#D59FC3",
        "secondary": "#597df5",
        "secondary-dark": "#4663C2",
        "secondary-light": "#A3B6F7",
        "tertiary": "#9dd0e3",
        "tertiary-dark": "#275363",
        "tertiary-light": "#5AC2E8",
      }
    },
  },
  plugins: [],
}
