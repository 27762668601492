import { DefaultTheme, createGlobalStyle, css } from "styled-components";
import { Config } from "tailwindcss";
import { OptionalConfig, RecursiveKeyValuePair, ResolvableTo, ScreensConfig } from "tailwindcss/types/config";
// @ts-ignore
import resolveConfig from "tailwindcss/resolveConfig";
// @ts-ignore
import tailwindConfig from "../../tailwind.config";

interface Theme extends DefaultTheme {
  colors?: ResolvableTo<RecursiveKeyValuePair<string, string>>;
  mediaQueries: { [key: string]: string; };
  widths: { [key: string]: number; };
}

const fullConfig: OptionalConfig = resolveConfig(tailwindConfig as Config);

const { colors, screens } = fullConfig.theme;

const getMediaQueries = (screens: ResolvableTo<ScreensConfig> | undefined): { [key: string]: string; } => {
  const mediaQueries: { [key: string]: string; } = {};
  for (const key in screens) {
    mediaQueries[key] = `(min-width: ${screens[key as keyof typeof screens]})`;
  }
  return mediaQueries;
};

const getWidths = (screens: ResolvableTo<ScreensConfig> | undefined): { [key: string]: number; } => {
  const widths: { [key: string]: number; } = {};
  for (const key in screens) {
    widths[key] = parseInt(screens[key as keyof typeof screens]);
  }
  return widths;
};

export const theme: Theme = {
  colors,
  mediaQueries: getMediaQueries(screens),
  widths: getWidths(screens),
};

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  :root {
    ${({ theme: { widths, mediaQueries } }) => css`
      font-size: ${(16 / widths.sm) * 100}vw;
      @media ${mediaQueries.md} {
        font-size: ${(16 / widths.xl) * 100}vw;
      }
    `}
  }`;