import React from "react";
import { GlobalStyle, theme } from "./src/styles/styles";
import { ThemeProvider } from "styled-components";
import { GatsbyBrowser } from "gatsby";
import "./src/styles/global.css";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
);
